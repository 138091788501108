exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3w-XguhjTU4mm0IPOZOD7A{height:100%;display:flex;justify-content:center;align-items:center}._1hTHUc6NgmSvfW_w_U5_2B{margin-right:15px;margin:3px 15px 0 0}", ""]);

// exports
exports.locals = {
	"spinnerContainer": "_3w-XguhjTU4mm0IPOZOD7A",
	"iconMargin": "_1hTHUc6NgmSvfW_w_U5_2B"
};