exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3yUXB5gkIIyfuke4-Oq0_p{padding-left:10px;padding-right:10px;padding-bottom:5px;margin-bottom:5px;font-weight:600}", ""]);

// exports
exports.locals = {
	"dropdownheader": "_3yUXB5gkIIyfuke4-Oq0_p"
};