import teinte from './variants/teinte';
// TODO: Create more themes

const colors = {
  teinte,
};

export default colors;
export {
  teinte,
};
