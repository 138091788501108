exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1DF9OEWZsFmavjw0hc5cKT{padding-left:10px;padding-right:10px;padding-bottom:5px;margin-bottom:5px;font-weight:600}._2WqpDrVsctwMkTvjR1RenM{margin-bottom:10px;list-style:none}._3xJE8pDtM2A6VLE8McsDPC{margin-top:10px}", ""]);

// exports
exports.locals = {
	"dropdownheader": "_1DF9OEWZsFmavjw0hc5cKT",
	"bottomSpacer": "_2WqpDrVsctwMkTvjR1RenM",
	"topSpacer": "_3xJE8pDtM2A6VLE8McsDPC"
};